import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { TokenService } from './token.service';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
import { NGXLogger } from "ngx-logger";
import { CartService } from './cart.service';
import { SocialAuthService as SocialService } from "angularx-social-login";
import swal from 'sweetalert2';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from './cookie.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

	user_info;

  	constructor(
  		@Inject(PLATFORM_ID) private platformId: Object,
  		private tokenService:TokenService,
  		public httpService:HttpService,
  		private router:Router, 
  		private logger: NGXLogger,
        private cartService: CartService,
        private socialService: SocialService,
        private cookieService: CookieService,
        private http:HttpClient,
  	) { }


  	//已登入導向指定頁面
  	loginRedirct(path) {
  		
		this.tokenService.infoState('member')
			.subscribe(res =>{
				if(res=='SUCCESS'){
					this.logger.debug(res);
					location.href = path;
					//this.router.navigate([path]);
				}
			});
	}


  	//進行登入 儲存資料
  	login(data) {

  		localStorage.removeItem('guest_id');
  		localStorage.setItem('token', data.token);
  		localStorage.setItem('user_info', JSON.stringify(data.user_info));

  		let user_info = localStorage.getItem('user_info');
        if(user_info){
  		    this.user_info = JSON.parse(user_info);
        }

        //取得購物車數量
        this.cartService.updateCartCount(data.cart_quantity);
        //this.getCartCount();
  	}



  	//guard 建強line自動登入
	clidLineLoginToken(clid):Observable<string>{

		

		const url = `${environment.APIUrl}/api/lab-auth/get-maac-user?clid=${clid}`;

		return this.http.get<any>(url).pipe(
			map(res => {
				//this.token_state = res.body.result;
				this.logger.debug(`clidLineLoginToken (guard): `);
				this.logger.debug(res);

				//如果沒任何錯誤，代表登入成功，進行登入流程
				if(!res.success_code){
					this.login(res);
				}

				//不管有沒有登入成功，都直接通過
				return 'SUCCESS';
				
			}),
			
		);
	}


  	//取得目前登入的使用者資料
  	getUserInfo() {
 
  		if(isPlatformBrowser(this.platformId)){
  			this.tokenService.infoState('member')
			.subscribe(res =>{
				if(res=='SUCCESS'){
					localStorage.removeItem('guest_id');
					//localStorage.removeItem('user_role');
					
					let user_info = localStorage.getItem('user_info');
        			if(user_info){
  		    			this.user_info = JSON.parse(user_info);
        			}
				}else{
					localStorage.removeItem('user_info');
				}
			});
  		}
  	}


  	//登出
  	logout(path='/') {
  		
  		if(isPlatformBrowser(this.platformId)){
	  		let url = `${environment.APIUrl}/api/lab-auth/logout`;

	  		this.httpService.postHttp(url,null).subscribe(
	  			res => {
	                localStorage.clear();
	                this.cookieService.deleteCookie('cart_discount_events');
	                this.user_info = null;
	                this.cartService.cart_count="0";
	                location.href = path;
	  			}
	  		);
	  	}
  	}


  	//登出
  	logoutWithoutAPI(redirect_url='/') {
  		if(isPlatformBrowser(this.platformId)){
	        localStorage.clear();
	        this.cookieService.deleteCookie('cart_discount_events');
	        this.user_info = null;
	        this.cartService.cart_count="0";
	        location.href = '/auth/login?url='+redirect_url;
	        //this.router.navigate(['/auth/login'], {queryParams: {url:redirect_url}});
	  	}
  	}


    //忘記密碼
    forgetPW(username){
        let url = `${environment.APIUrl}/api/lab-auth/forget-password`;
        let params = new FormData();
        params.append('username', username);

        this.httpService.postHttp(url, params).subscribe(
            res => {
                swal.fire({
                    icon: 'success',
                    text: '請至信箱收取新密碼信件'
                })
            }
        );
    }
   


    //透過API 找尋會員購物車數量
    getCartCount(){
    	if(isPlatformBrowser(this.platformId)){
	        let url=`${environment.APIUrl}/api/lab-shop/front/carts/quantity`;
	        let guest_id = localStorage.getItem('guest_id');
	        if(guest_id){
	        	url += `?guest_id=${guest_id}`;
	        }

	        this.httpService.loadingOpen = false;
	        this.httpService.getHttp(url).subscribe(
	            res => {
	            	this.httpService.loadingOpen = true;
	                this.cartService.updateCartCount(res.quantity);
	            }
	        )
	    }
    }


    //Google登入
    /*
    signInWithGoogle(redirect_url='/', role='member', register_url='/auth/register'): void {
    	
    	if(isPlatformBrowser(this.platformId)){
	        this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
	            (userData) => {
	                //console.log(userData);
	                this.socialLogin(userData,'fb', redirect_url, register_url);
	            }
	        );
	    }
    }*/



    //FB社群登入
    signInWithFB(redirect_url='/', role='member', register_url='/auth/register') {
    	
    	if(isPlatformBrowser(this.platformId)){
	        this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
	            (userData) => {
	                //console.log(userData);
	                this.socialLogin(userData,'fb', redirect_url, register_url);
	            }
	        );
	    }
    }


    //社群登入with labspace API
    socialLogin(user, provider, redirect_url='/', register_url='/auth/register') {

    	//必須確認有id
    	if(!user.id){
    		swal.fire({
    			icon: 'error',
    			text: '無法連結到你的社群網站, 請改用帳號密碼登入'
    		});
    		return;
    	}

        //login system api
        let params:FormData = new FormData();
        params.append('social_id', user.id);
        params.append('provider', provider);
        params.append('email', user.email);
        //console.log(this.user_id);

        //訪客登入時 購物車資料移轉
	  	let guest_id = localStorage.getItem('guest_id');
	  	if(guest_id){
	  		params.append('guest_id', guest_id);
	  	}

        const url = `${environment.APIUrl}/api/lab-auth/social-login`;

        this.httpService.postHttp(url,params).subscribe(
            res => { 
                //console.log(res);
                let output_data = {};

                //login 失敗
                if(res.success_code){
                    switch (res.success_code) {
                        //需要註冊
                        case "PLEASE_REGISTER":
                            this.router.navigate([register_url], { queryParams: user });
                        break;
                    }

                //login 成功
                }else{
                    this.login(res);
                    location.href = redirect_url;
                    /*
                    swal.fire({
                        title: '登入成功',
                        icon: 'success'
                    }).then((result)=>{
                        //this.router.navigate([redirect_url]);
                        location.href = redirect_url;
                    });*/
                }

        
            }
        );
    }
}
