import { Component, OnInit, Input, Inject, PLATFORM_ID, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { HttpService } from '../../services/http.service';
import { TreeService } from '../../services/tree.service';
import { environment } from '../../../environments/environment';
import { FileService } from '../../services/file.service';
import { isPlatformBrowser } from '@angular/common';
import {  Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router'; //接收route para
import { Menu } from '../models/menu.model';

@Component({
  selector: 'app-product-menu',
  templateUrl: './product-menu.component.html',
  styleUrls: ['./product-menu.component.scss']
})
export class ProductMenuComponent implements OnInit {

	@Input() discount_events;
	@Input() level = 1;
	@Input() discount_event_menu_banner; //menu 行銷活動廣告
	@Input() product_menu_banner; //menu 商品分類廣告
	@Output() routeClick = new EventEmitter<any>(); //提供Output裝飾器，讓該事件成為父親模板的事件
	
	list;

	shop_id = environment.default_shop_id;
	filepath = environment.FileUrl;
	defaultImage = environment.lazyLoad_defaultImg;

	img_src;
	open_sub = {};
	sub_id;
	isEnter;
	mobile;
	list_ids;
	
	keyword;

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		public authService: AuthService,
		private httpService: HttpService,
		private treeService: TreeService,
		public fileService: FileService,
		private router: Router
	) { }

	ngOnInit(): void {

		//this.getList();
		this.getProductCategoryList();

		if(isPlatformBrowser(this.platformId)){
			if(window.screen.width < 992){
				this.mobile = true;
			}
		}
	}


	//取得商品類別資訊
	getProductCategoryList(parent_id=null) {
		let url = `${environment.APIUrl}/api/lab-shop/front/categories?shop_id=${this.shop_id}&channel=default&`;
		if(parent_id){
			url += `parent_id=${parent_id}&`
		}
		this.httpService.getHttp(url).subscribe(
			res => {
				this.list = this.treeService.nbTreeFormat(res.items);
				console.warn(this.list);
			}
		)
	}


	//取得類別資訊
	getList(){
		this.httpService.loadingOpen = false;
		let url = `${environment.APIUrl}/api/lab-web-content/front/menu-data`;
		this.httpService.getHttp(url).subscribe(
			res => {

				/*
					設定認識產品第二層
				 */
				this.list.filter(item => item.code=='menu.introduction').map(menu => {
					menu.children = [];
					res.introduction_categories.forEach(category => {
						menu.children.push(
							{
								code: category.name,
								link: ['/content/introduction', category.id],
							}
						)
					})
				})

				/*
					設定生活提案第二層				
				 */
				this.list.filter(item => item.code=='menu.blog').map(menu => {
					menu.children = [];
					res.blog_categories.forEach(category => {
						menu.children.push(
							{
								code: category.name,
								link: ['/content/blog', category.id],
							}
						)
					})
				})

				/*
					設定產地直人第二層				
				 */
				this.list.filter(item => item.code=='menu.review').map(menu => {
					menu.children = [];
					res.review_categories.forEach(category => {
						menu.children.push(
							{
								code: category.name,
								link: ['/content/review', category.id],
							}
						)
					})
				})



				/*
					設定網路訂購第二層
				 */
				this.list.filter(item => item.code=='menu.shop').map(menu => {
					menu.children = [];

					//定期配送文字
					if(res.ec.subscription_categories.length>0){
						menu.children.push(
							{
								code: 'menu.productSubscription',
								link: null,
							}
						)
					}
					
					//定期配送類別
					res.ec.subscription_categories.forEach(category => {
						menu.children.push(
							{
								code: category.name,
								link: ['/products', category.url_code],
							}
						)
					})

					//一般訂購自選配送
					if(res.ec.default_categories.length>0){
						menu.children.push(
							{
								code: 'menu.productDefault',
								link: null,
							},
							{
								code: 'menu.shopHome',
								link: ['/products/home'],
							},
						);
					}

					//一般訂購類別
					res.ec.default_categories.forEach(category => {
						menu.children.push(
							{
								code: category.name,
								link: ['/products', category.url_code],
							}
						)
					})

					//點數商品
					if(res.ec.if_shopcash_product==1){
						menu.children.push(
							{
								code: 'menu.shopcashProductHome',
								link: null,
							},
							{
								code: 'menu.shopcashProduct',
								link: ['/products/a'],
							},
						);
					}

					//行銷活動
					if(res.ec.discount_events.length>0){
						menu.children.push(
							{
								code: 'menu.discountEvent',
								link: null,
							},
						);
					}
					//指定商品行銷活動清單
					res.ec.discount_events.forEach(item => {
						menu.children.push(
							{
								code: item.name,
								link: ['/discount', item.id],
							}
						)
					})

					//訂購說明頁
					menu.children.push(
						{
							code: 'menu.shopIntro',
							link: null,
						},
						{
							code: 'menu.subscriptionIntro',
							link: '/products/introduction/subscription',
						},
						{
							code: 'menu.productIntro',
							link: '/products/introduction/default',
						},
					);
					
				})
				this.httpService.loadingOpen = true;
				
			}
		);
	}


	toggleSubMenu(id) {
		this.open_sub = {};
		this.open_sub[id] = true;
	}


	openSubMenu(id) {
		if(this.mobile){
			this.list_ids = this.list.map(item => item.id);
			this.list_ids.forEach(item => this.open_sub[item] = false);
			this.open_sub['discount'] = false;
			this.open_sub[id] = true;
		}else{
			this.open_sub[id] = true;
		}
	}


	closeSubMenu(id){
		//this.open_sub = false;
		setTimeout(() => {
			this.open_sub[id] = false;
		}, 100);
	}


	//進入產品列表頁 output到母類別
	outputEvent(){
		this.routeClick.emit('goto');

		if(this.mobile){
			if(isPlatformBrowser(this.platformId)){
			  document.getElementById('navbar').classList.remove('show');
			  document.getElementById('toggle').setAttribute('aria-expanded', 'false');
			}
		}
	}


	//尋找類別code的最上層類別
	searchTopCategory(code) {
		let url = `${environment.APIUrl}/api/lab-shop/front/categories/${code}/parents?search_type=url_code`;
		this.httpService.getHttp(url).subscribe(
			res => {

				//let keys = Object.keys(res.items);
				//console.warn(keys);

				this.list_ids = this.list.map(item => item.id);
				this.list_ids.forEach(item => this.open_sub[item] = false);

				//最上層類別打開
				this.open_sub[res.items[0].id] = true;
				//console.warn(this.open_sub);
				//this.product_menu_banner = this.filepath+res.items[0].data.menu_banner;
			}
		)
	}


	//站內搜尋
	webSearch(){
		if(this.keyword){
			this.router.navigate(['/search', this.keyword, 'product']);
			this.routeClick.emit('goto');
		}
	}

}
