<!-- 購物車無商品 -->
<section class="section-empty py-0" *ngIf="cart_empty">
	<div class="container px-3">
		
		<!-- 操作按鈕 -->
		<div class="sidebar-close">
			<button type="button" class="btn btn-sm btn-icon px-0" (click)="close()">
				<nb-icon icon="close"></nb-icon>
			</button>
		</div>

		<div class="container">
			<img src="/assets/images/icon/cart.svg" class="icon">
			<p>購物車目前無商品</p>
		</div>

	</div>
</section>


<!-- 購物車資訊 -->
<div class="container px-3" *ngIf="cart">

	<!-- 操作按鈕 -->
	<div class="sidebar-close">
		<button type="button" class="btn btn-sm btn-icon px-0" (click)="close()">
			<nb-icon icon="close"></nb-icon>
		</button>
	</div>

	<!-- 購物車數量 -->
	<div class="cart-title">購物車數量總計：{{ cart.sum_quantity }}</div>


	<!-- 訂單商品 -->
	<!-- 符合特定商品活動的商品清單 -->
	<div *ngIf="sku_items.match">
		<ng-container *ngFor="let item of sku_items.match">
			<div *ngIf="item.discount_event_info.type=='product-special'">
				<!-- 行銷活動資訊 -->
				<div class="event-title">
					<a [routerLink]="[discount_link, item.discount_event_info.id]" class="title">{{ item.discount_event_info.name }}</a>
					<div  *ngIf="item.discount_event_info.discount_amount!=0">
						<span>折
							<span *ngTemplateOutlet="price;context: {$implicit: item.discount_event_info.discount_amount}"></span>
						</span>
					</div>
				</div>

				<!-- 商品列表 -->
				<ng-container *ngIf="item.discount_event_info.code!='ab'">
					<ng-container *ngFor="let product of item.skus">
						<!-- 主商品 -->
						<ul class="table-resp table-resp-cart">
							<li class="row gx-sm-5 header">
							  <div class="col-sm-5">商品</div>
							  <div class="col-sm-2">單價</div>
							  <div class="col-sm-2">數量</div>
							</li>
							<li class="row gx-sm-5 detail">
								<div *ngTemplateOutlet="singleProduct; context: {$implicit: product}"></div>
							</li>
						</ul>	
						<!-- 子商品 -->
						<div class="row gx-0 children" *ngIf="product.children">
							<div class="col-sm-1 title">{{ 'cart_product.child' | translate }}</div>
							<div class="col-sm">
								<ul class="table-resp table-resp-cart">
									<ng-container *ngFor="let child_product of product.children">
										<li class="row gx-sm-5 detail">
											<div *ngTemplateOutlet="singleChildProduct; context: {$implicit: child_product, type:product.type} "></div>
										</li>
									</ng-container>
								</ul>
							</div>
						</div>
					</ng-container>
				</ng-container>

				<!-- 任選配對商品列表 -->
				<ng-container *ngIf="item.discount_event_info.code=='ab'">
					<ng-container *ngFor="let product of item.skus">

						<!-- 主商品 -->
						

						<!-- a區子商品 -->
						<div class="row gx-0 children">
							<div class="col-sm-1 title">{{ product.discount_event.a_name }}</div>
							<div class="col-sm">
								<ul class="table-resp table-resp-cart">
									<li class="row gx-sm-5 header">
									  <div class="col-sm-5">商品</div>
									  <div class="col-sm-2">單價</div>
									  <div class="col-sm-2">數量</div>
									</li>
									<ng-container *ngFor="let child_product of product.children">
										<ng-container *ngIf="child_product.area_zone=='a_zone'">
											<li class="row gx-sm-5 detail">
												<div *ngTemplateOutlet="singleChildProduct; context: {$implicit: child_product, type:product.type} "></div>
											</li>
										</ng-container>
									</ng-container>
								</ul>
							</div>
						</div>

						<!-- b區子商品 -->
						<div class="row gx-0 children">
							<div class="col-sm-1 title">{{ product.discount_event.b_name }}</div>
							<div class="col-sm">
								<ul class="table-resp table-resp-cart">
									<ng-container *ngFor="let child_product of product.children">
										<ng-container *ngIf="child_product.area_zone=='b_zone'">
											<li class="row gx-sm-5 detail">
												<div  *ngTemplateOutlet="singleChildProduct; context: {$implicit: child_product, type:product.type} "></div>
											</li>
										</ng-container>
									</ng-container>
								</ul>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</ng-container>
	</div>

	<!-- 未符合活動的商品清單 -->
	<div *ngIf="sku_items.not_match">

		<!-- 商品列表 -->
		<ul class="table-resp table-resp-cart border-bottom mb-0">
		  <li class="row gx-sm-5 header">
		    <div class="col-sm-5">商品</div>
		    <div class="col-sm-2">單價</div>
		    <div class="col-sm-2">數量</div>
		  </li>
		  <li class="row gx-sm-5 detail" *ngFor="let product of sku_items.not_match">

		  	<div *ngTemplateOutlet="singleProduct; context: {$implicit: product}"></div>

		  	
		  	<!-- 子商品 -->
		  	<div class="row children" *ngIf="product.children">
		  		<div class="col-sm-1">{{ 'cart_product.child' | translate }}</div>
		  		<div class="col-sm">
		  			<ng-container *ngFor="let child_product of product.children">
		  				<div *ngTemplateOutlet="singleChildProduct; context: {$implicit: child_product, type:product.type} "></div>
		  			</ng-container>	
		  		</div>
		  	</div>

		  </li>
		</ul>
			
	</div>


	<!--- 免運券資訊 -->
	<div class="p-3" *ngIf="shipping_coupon_feedback">
		<span class="text-secondary fw-normal text-sm" *ngIf="shipping_coupon_feedback.next_level_count==0">
			未達免運門檻：您還差{{ shipping_coupon_feedback.next_level_amount }}元即可享免運
		</span>
		<span class="text-secondary fw-normal text-sm" *ngIf="shipping_coupon_feedback.next_level_count>0">
			已達免運門檻：您還差{{ shipping_coupon_feedback.next_level_amount }}元可多獲得{{ shipping_coupon_feedback.next_level_count }}張免運券
		</span>
	</div>



	<!--- 訂單明細 -->
	<div *ngTemplateOutlet="orderTotal; context: {$implicit: order_summary}"></div>


	<!-- 操作按鈕 -->
	<div class="text-center mt-4 pt-2">
		<a class="btn btn-outline-dark" [routerLink]="['/cart/order']" (click)="close()">
			前往結帳<nb-icon icon="chevron-right"></nb-icon>
		</a>
	</div>

</div>




<!-- 
	單一主商品資訊 
-->
<ng-template #singleProduct let-product let-discount_event="discount_event">

	<div class="col-12">
	  <!-- <span class="label">商品</span> -->
	  <div>
	  	<a class="image" *ngIf="product.type!='combo'" [routerLink]="[product_info_link, product.sku_model]" >
	  		<img [src]="filepath+fileService.getThumbnail(product.image,'xs')">
	  	</a>
	  	<a class="image" *ngIf="product.type=='combo'" [routerLink]="[discount_link, product.discount_event_id]" >
	  		<img [src]="filepath+fileService.getThumbnail(product.image,'xs')">
	  	</a>
	  	<div class="info">
	  		<!-- 商品編號 -->
	  		<!-- <span class="text-muted">{{ 'cart_product.model' | translate }}：{{ product.model }}</span> -->

	  		<!-- 商品連結 -->
	  		<a *ngIf="product.type!='combo'" [routerLink]="[product_info_link, product.sku_model]">{{ product.product_name }}</a>
	  		<a *ngIf="product.type=='combo'" [routerLink]="[discount_link, product.discount_event_id]">{{ product.product_name }}</a>


	  		<!-- 商品規格 -->
	  		<div class="specs" *ngIf="product.options_json">
	  			<span class="item" *ngFor="let option of product.options_json | keyvalue">
	  				<!-- {{ option.key }}： -->{{ option.value }}
	  			</span>
	  		</div>
	  	</div>
	  </div>
	</div>


	<!-- 商品單價 -->
	<div class="col-12">
	  <span class="label">
	  	<ng-container [ngSwitch]="product.type">
	  		<ng-container *ngSwitchCase="'discount_event_addon'">
	  			{{ 'product.price.addon' | translate }}
	  		</ng-container>
		  	<ng-container *ngSwitchDefault>
		  		單價
		  	</ng-container>
	  	</ng-container>
	  </span>
	  <div>
	  	<ng-container [ngSwitch]="product.type">
	  		<ng-container *ngSwitchCase="'discount_event_addon'">
	  			${{ product.after_discount_price }}
	  		</ng-container>
	  		<ng-container *ngSwitchCase="'discount_event_free'">
	  			{{ 'product.price.free' | translate }}
	  		</ng-container>
	  		<ng-container *ngSwitchCase="'dollbao'">
	  			<span>{{ 'product.price.checkout' | translate }}：${{ product.price }} + {{ 'product.price.dollbao' | translate }}{{ product.d_price }}</span>
	  		</ng-container>

	  		<!-- 顯示定價與結帳架 -->
	  		<ng-container *ngSwitchDefault>
	  			<span class="discount"><!-- {{ 'product.price.checkout' | translate }}： -->${{ product.price }}</span>
	  			
	  		</ng-container>
	  	</ng-container>
	  </div>
	</div>

	<!-- 數量 -->
	<div class="col-12">
	  <span class="label label-form">數量</span>
	  <div>
	  	{{ product.quantity }}

	  	
	  </div>
	</div>


</ng-template>


<!-- 
	單一子商品資訊 
-->
<ng-template #singleChildProduct let-product let-type="type">


		<!-- 商品圖片 -->
		<div class="col-12">
			<!-- <span class="label">商品</span> -->
			<div>
				<a class="image" [routerLink]="[product_info_link, product.sku_model]" >
					<img [src]="filepath+fileService.getThumbnail(product.image,'xs')">
				</a>
				<div class="info">
					<!-- 商品編號 -->
					<!-- <span class="text-muted">{{ 'cart_product.model' | translate }}：{{ product.model }}</span> -->

					<!-- 商品連結 -->
					<a [routerLink]="[product_info_link, product.sku_model]" >{{ product.product_name }}</a>
					<span *ngIf="type=='multi'">{{ 'cart_product.match.multi' | translate }} - {{ 'cart_product.child' | translate }}</span>
					<span *ngIf="type=='combo'">{{ 'cart_product.match.combo' | translate }} - {{ 'cart_product.child' | translate }}</span>

					<!-- 商品規格 -->
					<div class="specs">
						<span class="item" *ngFor="let option of product.options_json | keyvalue">
							<!-- {{ option.key }}： -->{{ option.value }}
						</span>
					</div>
				</div>
			</div>
		</div>


		<!-- 商品單價 -->
		<div class="col-12">
		  <span class="label">單價</span>
		  <div>
	  		<!-- 商品價格 only for AB -->
	  		<ng-container *ngIf="type=='ab'">
	  			<span class="discount" *ngIf="product.discount_event_match && product.price!=product.after_discount_price">${{ product.after_discount_price }}</span>
	  			<span class="original">${{ product.price }}</span>
	  		</ng-container>
		  </div>
		</div>


		<!-- 數量 -->
		<div class="col-12">
		  <span class="label label-form">數量</span>
		  <div>
		  	{{ product.quantity }}
		  </div>
		</div>

		
</ng-template>



<!--- 金錢顯示格式 -->
<ng-template #price let-input>
	<span *ngIf="input<0">-${{ -input }}</span>
	<span *ngIf="input>=0">${{ input }}</span>
</ng-template>



<!-- 總價資訊 -->
<ng-template #orderTotal let-order_summary>
	<div class="cart-title mt-2">訂單資訊</div>
	
	<input type="checkbox" class="control d-none" id="toggler">
	<div class="cart-list-collapse">
		<div class="cart-list">
			<!-- 紅利點數使用明細 -->
			<div class="cart-list-item" *ngIf="order_dollbao_summary.dollbao_product_total!=0">
				<span>使用紅利點數數量(兌換商品)</span>
				<span>{{ order_dollbao_summary.dollbao_product_total }}</span>
			</div>


			<!-- 訂單金額明細 （台幣） -->
			<div class="cart-list-item">
				<span>訂單商品小計</span>
				<span *ngTemplateOutlet="price;context: {$implicit: order_summary.product_total}"></span>
			</div>
			<div class="cart-list-item" *ngIf="order_summary.discount_event!=0">
				<span>行銷活動優惠</span>
				<span *ngTemplateOutlet="price;context: {$implicit: order_summary.discount_event}"></span>
			</div>
			<div class="cart-list-item" *ngIf="order_summary.member_discount_amount!=0">
				<span>會員等級優惠</span>
				<span *ngTemplateOutlet="price;context: {$implicit: order_summary.member_discount_amount}"></span>
			</div>
			

			<!-- 運費 -->
			<div class="cart-list-item" *ngIf="shipping_fee!=0">
				<span>運費</span>
				<span *ngTemplateOutlet="price;context: {$implicit: shipping_fee}"></span>
			</div>

		</div>
	</div>

	<div class="cart-total">
		<span>訂單總計</span>
		<span class="price"><b><span *ngTemplateOutlet="price;context: {$implicit: currentCheckTotal()}"></span></b></span>
		<label for="toggler" class="toggler">
			<nb-icon icon="arrow-ios-downward"></nb-icon>
		</label>
	</div>
</ng-template>


