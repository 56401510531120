import { Injectable, Inject, PLATFORM_ID, Output, EventEmitter } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { GaService } from './ga.service';
import swal from 'sweetalert2';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from './cookie.service';
import { CartProductComponent } from '../share/cart-product/cart-product.component';
import { NbDialogService } from '@nebular/theme';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para


@Injectable({
  	providedIn: 'root'
})
export class CartService {

	//Output event
	addCartFinish = new EventEmitter<any>();
	outputEvent(eventdata) {
    	this.addCartFinish.emit(eventdata);
  	}
  	//給component訂閱output使用
  	getChangeEmitter() {
    	return this.addCartFinish;
  	}

	cart_count="0"; //購物車數量

	discount_events = {}; //購物車裡行銷活動參與資訊

  	constructor(
  		@Inject(PLATFORM_ID) private platformId: Object,
  		private httpService: HttpService,
  		private translate: TranslateService,
  		private gaService: GaService,
  		private cookieService: CookieService,
  		private dialogService: NbDialogService,
  		private route: ActivatedRoute,
  		private router: Router
  	) { }


  	//加入購物車
  	addCart(quantity, product_sku_id, type='general', children=null, discount_event_id=null){

  		let cart_object = {
  			type: type,
  			quantity: quantity,
  			product_sku_id: product_sku_id,
  			if_reload: environment.cartDiscountReload.write,
  			if_discount_event: 1
  		}

  		switch(type){
  			//多料號子商品處理
  			case "multi":
  				let children_obj = {};
	  			Object.keys(children).forEach(key => {
	  				children_obj[key] = [{quantity:1, product_sku_id:children[key]}];
	  			});
	  			cart_object['children'] = JSON.stringify(children_obj);
  			break;

  			//組合包子商品處理
  			case "combo":
  				cart_object['children'] = JSON.stringify(children);
  			break;

  			//任選配對商品
  			case "ab":
  				cart_object['ab_children'] = JSON.stringify(children);
  			break;

  			//單一商品的加購品
  			case "product_addon":
  				cart_object['relate_product_id'] = children;
  			break;
  		}

  		//活動加入
  		if(discount_event_id){
  			cart_object['discount_event_id'] = discount_event_id;
  		}

  		//檢查token是否有登入
  		let token = localStorage.getItem('token');
  		let url = `${environment.APIUrl}/lab/api/user/check?token=${token}&role=member`;
  		this.httpService.getHttp(url).subscribe(
  			res =>{
  				//success_code存在代表不是SUCCESS 未登入成功(訪客)
  				if(res.success_code){
  					/*
  					swal.fire({
		  				icon: 'error',
		  				title: '請先登入',
		  				showCancelButton: true,
		  				confirmButtonText: `登入`,
		  			}).then((result) => {
		  				if (result.value) {
		  					this.router.navigate(['/auth/login'], {queryParams:{url:this.router.url}})
		  				}
		  			});*/
  					this.guestAddCart(cart_object);
  				//會員
  				}else{
  					this.memberAddCart(cart_object);
  				}
  			}
  		)
  	}



  	//訪客加入購物車
  	guestAddCart(cart_object){
  		let params = new FormData();

  		//確認是否有guest_id
  		let guest_id = this.getGuestId();
  		if(guest_id){
  			params.append('guest_id', guest_id);
  		}

  		Object.keys(cart_object).map(key=> params.append(key,cart_object[key]));

  		let url = `${environment.APIUrl}/api/lab-shop/front/carts/guest`;

  		this.httpService.postHttp(url, params).subscribe(
  			res => {

  				
  				localStorage.setItem('guest_id',res.guest_id);
  				this.updateCartCount(res.quantity);

  				//ga
  				this.gaService.gaAddCart(res.skus);

  				
  				if(isPlatformBrowser(this.platformId)){

  					//加購品回傳資訊
  					if(cart_object.type=='discount_event_addon'){
	  					//location.reload();
	  					this.outputEvent(res.skus[0].product_id);
	  				//其他商品回傳資訊
	  				}else{
	  					
	  					this.discount_events = {};
	  					res.discount_events.forEach(event=>{
	  						delete event.name;
	  						delete event.description;
	  						this.discount_events[event.id]=event;
	  					});
	  					this.cookieService.setCookie('cart_discount_events', JSON.stringify(this.discount_events));
	  					this.outputEvent(res.skus[0].product_id);
	  				}

	  				//打開購物車popup
  					this.openCartDialog(res);
  				}
  					
  				
  			}
  		);
  	}


  	//會員加入購物車
  	memberAddCart(cart_object){
  		let params = new FormData();
  		Object.keys(cart_object).map(key=> params.append(key,cart_object[key]));

  		let url = `${environment.APIUrl}/api/lab-shop/front/carts/member`;

  		this.httpService.postHttp(url, params).subscribe(
  			res => {
  				//console.warn('hihi addCart');

  				this.updateCartCount(res.quantity);

  				//ga
  				this.gaService.gaAddCart(res.skus);
  				/*
  				swal.fire({
  					icon: 'success',
  					text: this.translate.instant('common.success')
  				}).then(result=>{
  				*/
  					if(isPlatformBrowser(this.platformId)){

  						//加購品回傳資訊
  						if(cart_object.type=='discount_event_addon'){
	  						//location.reload();
	  						this.outputEvent(res.skus[0].product_id);
	  					//其他商品回傳資訊
	  					}else{
	  						
	  						this.discount_events = {};
	  						res.discount_events.forEach(event=>{
	  							delete event.name;
	  							delete event.description;
	  							this.discount_events[event.id]=event;
	  						});
	  						this.cookieService.setCookie('cart_discount_events', JSON.stringify(this.discount_events));
	  						this.outputEvent(res.skus[0].product_id);
	  					}

	  					//打開購物車popup
  						this.openCartDialog(res);
  					}
  				//});

  			}
  		);
  	}



  	//更新購物車數量
    updateCartCount(num=null){
        if(num!=null){
            localStorage.setItem('cart_count', num);
        }
        this.cart_count = localStorage.getItem('cart_count');
    }


    //設定訪客購物車ID
    setGuestId(guest_id){
    	localStorage.setItem('guest_id',guest_id);
    }


    //取得訪客購物車ID
    getGuestId(){
    	return localStorage.getItem('guest_id');
    }


    //儲存訂單資料
    saveCartOrder(cart_id, form_value){
        let data = JSON.stringify(form_value);
        localStorage.setItem(`cart_${cart_id}`,data);
    }


    //取得訂單資料並刪除
    getCartOrder(cart_id){
        let data = localStorage.getItem(`cart_${cart_id}`);
        localStorage.removeItem(`cart_${cart_id}`);
        return JSON.parse(data);
    }


    //貨到通知
    deliveryNotice(product_id, product_sku_id){

    	let user = localStorage.getItem('user_info');
    	let email;
    	if(user){
    		email = JSON.parse(user).email;
    	}

        swal.fire({
          title: '請輸入貨到通知信箱',
          input: 'email',
          inputValue: email,
          inputPlaceholder: 'Enter your email address'
        }).then((result) => {
            if(result.value){
                let url = `${environment.APIUrl}/api/lab-shop/front/delivery-notice`;
                let params = new FormData();
                params.append('email',result.value);
                params.append('product_id',product_id);
                params.append('product_sku_id',product_sku_id);
                this.httpService.postHttp(url,params).subscribe(
                    res => {
                        swal.fire({
                            icon: 'success',
                            text: this.translate.instant('common.success')
                        })
                    }
                )
            }
        })
    }


    //加入收藏
    /*
       商品為type=products
     */
    addFavorite(type, id, action='1'){
    	//let action = '1';
    	
        let params = new FormData();
        params.append('type', type);
        params.append('id', id);
        params.append('action', action);

        let url = `${environment.APIUrl}/api/lab-member-center/front/favorite/toggle`;
        this.httpService.postHttp(url, params).subscribe(
            res => {
            	/*
                swal.fire({
                    icon: 'success',
                    text: this.translate.instant('common.success')
                })
                */
            }
        )
    }


    //加入購物車後的動作 打開剛加入的商品畫面
  	openCartDialog(res=null){

  		/*
  		this.dialogService.open(CartProductComponent,
  			{ 
                context: {
                    api_res: res
                },
                hasBackdrop: false
            }
  		).onClose.subscribe(result => {
            
        });*/
        /*
        swal.fire({
        	icon: 'success',
        	text: '您已成功加入購物車',
        	showCancelButton: true,
  			confirmButtonText: '前往結帳',
  			cancelButtonText: '繼續購物',
        }).then((result) => {
        	if(result.value){
        		this.router.navigate(['/cart/order'])
        	}
		  	
		})*/

		swal.fire({
			icon: 'success',
		  	text: '您已成功加入購物車',
		  	timer: 1000,
		})
  	}


  	//離島運費
  	extraShippingFee(address) {
  		let cities = ['金門縣','連江縣','澎湖縣'];
  		let distrcts = ['綠島鄉'];
  		if(cities.includes(address.city) || distrcts.includes(address.distrct)){
  			return 110;
  		}else{
  			return 0;
  		}
  	}


    
}
