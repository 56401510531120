<section class="section-index py-0" *ngIf="data">

	<ng-container *ngIf="data.home_top_banners">
		<app-banner [list]="data.home_top_banners" customClass="hero-banner"></app-banner>
	</ng-container>

</section>


<!-- 建構好巢 -->
<section class="section-index">
	<div class="container">

		<div class="section-header section-header mt-4 mt-lg-5 mb-2 mb-lg-3">
			<h2 class="brand-title text-primary">建構好巢，就來好巢！</h2>
		</div>

		<p class="brand-text fs-5 lh-lg text-center mb-4 mb-lg-5">
			提升每位家庭成員的幸福指數是我們的夢想。<br>
			從選品到服務，就像對待自己的家人一般，<br class="d-sm-none">我們溫暖盡心。<br>
			<span class="h4 text-secondary">“生活可以再美好一點”</span><br>
			跟著我們一起逐步實現家之於家人1+1>2的生活品質吧！
		</p>

		<div class="brand-logo text-center">
			<img src="/assets/images/logo_notext.svg">
		</div>

	</div>
</section>


<!-- 熱門好物 -->
<section class="section-index" *ngIf="data">
	<div class="container">

		<div class="section-header mb-4 mb-lg-5">
			<nav class="nav nav-tabs">
			  <!-- <a class="nav-link mx-5">熱門服務</a> -->
			  <a class="nav-link mx-5 active">熱門好物</a>
			</nav>
		</div>

		<div class="row gy-4 gx-3 gx-md-4">
			<div class="col-6 col-md-4 col-lg-3" *ngFor="let item of data.hot_products">
				<app-home-product-item [item]="item.model_info"></app-home-product-item>
			</div>
		</div>

		<div class="text-center mt-4">
			<a [routerLink]="['/products']" class="btn btn-shadow-light">查看更多 >></a>
		</div>

	</div>
</section>


<!-- 大圖廣告 -->
<section class="section-index" *ngIf="data && data.home_middle_banners">
	<div class="pade-center-ad" *ngIf="data.home_middle_banners.length>0">
		<div *ngTemplateOutlet="singleBanner; context: {$implicit: data.home_middle_banners[0]}"></div>
	</div>
</section>


<!-- 小圖廣告-->
<section class="section-index" *ngIf="data && data.star_banner">
	<div class="container" *ngIf="data.star_banner.length>0">

		<div class="row g-4">
			<div class="col-6 col-md-4" *ngFor="let item of data.star_banner">
				<div class="card-ad-item">
					
					<ng-container *ngIf="item.file_type=='image'">
						<a *ngIf="item.link_type=='in'" [routerLink]="[item.url]">
							<img [src]="filepath+fileService.getThumbnail(item.filepath,'s')" class="img-fluid">
							<img *ngIf="mobile && item.m_filepath" [src]="filepath+fileService.getThumbnail(item.m_filepath,'s')"  class="img-fluid">
						</a>
						<a *ngIf="item.link_type=='out'" href="{{ item.url }}" target="_blank">
							<img [src]="filepath+fileService.getThumbnail(item.filepath,'s')" class="img-fluid">
							<img *ngIf="mobile && item.m_filepath" [src]="filepath+fileService.getThumbnail(item.m_filepath,'s')" class="img-fluid">
						</a>
						<ng-container *ngIf="item.link_type=='none'">
							<img [src]="filepath+fileService.getThumbnail(item.filepath,'s')" class="img-fluid">
							<img *ngIf="mobile && item.m_filepath" [src]="filepath+fileService.getThumbnail(item.m_filepath,'s')" class="img-fluid">
						</ng-container>
					</ng-container>

					<ng-container *ngIf="item.file_type=='video'">
						<a *ngIf="item.link_type=='in'" [routerLink]="[item.url]"><video controls [src]="filepath+item.filepath"></video></a>
						<a *ngIf="item.link_type=='out'" href="{{ item.url }}" target="_blank"><video controls [src]="filepath+item.filepath"></video></a>
						<video *ngIf="item.link_type=='none'" controls [src]="filepath+item.filepath"></video>
					</ng-container>
				</div>
				
			</div>
		</div>
			
	</div>
</section> 


<!-- 文案廣告 -->
<section class="section-index" *ngIf="data && data.home_bottom_banners">
	<div class="page-end-ad" *ngIf="data.home_bottom_banners.length>0">
		<div *ngTemplateOutlet="singleBanner; context: {$implicit: data.home_bottom_banners[0]}"></div>
	</div>
</section>


<!-- 精選家務指南 -->
<!-- <section class="section-index">
	<div class="container">
		
		<div class="row">
			<div class="col-md-4">
				<h4>精選家務指南</h4>
				<p>Selected Guide</p>
			</div>
			<div class="col-md-8">

				<div class="row gy-4">
					<div class="col-md-4">
						
						<div class="card-article">
							<div class="card-image">
								<a href="">
									<img src="https://shop-module.labspace.com.tw/storage/ckeditor/1/thumbs_s/20220830225403422.jpg" alt="">
								</a>
								<button type="button" class="btn-favorite">
									<nb-icon icon="heart"></nb-icon>
								</button>
							</div>
							<div class="card-body">
								<a href="">
									<p class="card-subtitle">精選文章・居家生活</p>
									<h5 class="card-title">生活電商、家電平台那麼多，好巢網有什麼不一樣？</h5>
									<p class="card-text">這是當初在發想好巢網時，身邊的人最常問的問題。 如果真的要說有什麼不一樣？ 那麼答案可能是 ”的確沒什麼不一樣“ 但 “其實也不一樣”。</p>
									<span class="row">
										<span class="col-md">小維媽</span>
										<span class="col-md-auto">2021-01-04</span>
									</span>
								</a>
							</div>
						</div>

					</div>
				</div>

			</div>
		</div>

	</div>
</section> -->


<section class="section-index">
	<div class="container">

		<div class="section-header">
			<h3 class="title">好巢網能為你做什麼？</h3>
			<p class="text">what we can do for you.</p>
		</div>

		<div class="brand-overflow-auto">
			<div class="row gx-0">

				<div class="col-3">
					<div class="card-brand-item">
						<div class="icon">
							<img src="/assets/images/brand/icon_01.svg" alt="">
						</div>
						<h4 class="title">家電履歷管理系統</h4>
						<p class="text">輕鬆管理家電保固及修繕資料。再也不需東翻西找保固書。輕鬆線上整合家中每一項家電紀錄！</p>
					</div>
				</div>

				<div class="col-3">
					<div class="card-brand-item">
						<div class="icon">
							<img src="/assets/images/brand/icon_02.svg" alt="">
						</div>
						<h4 class="title">專業親切的團隊</h4>
						<p class="text">透過嚴格的篩選及把關，好巢網只跟最可靠，最可信賴的服務人員及供應商合作。</p>
					</div>
				</div>

				<div class="col-3">
					<div class="card-brand-item">
						<div class="icon">
							<img src="/assets/images/brand/icon_03.svg" alt="">
						</div>
						<h4 class="title">最貼近生活的選品</h4>
						<p class="text">兼具實用與美學的選品團隊，從挑選、試用、上架層層把關。讓你不再買到不適合的商品，提升家的生活質量。</p>
					</div>
				</div>

				<div class="col-3">
					<div class="card-brand-item">
						<div class="icon">
							<img src="/assets/images/brand/icon_04.svg" alt="">
						</div>
						<h4 class="title">貼心的售後服務</h4>
						<p class="text">事情太多記不住沒問題！相關環節幫你清楚紀錄，隨時查詢服務進度。也不需東翻西找保固書。輕鬆線上整合家中每一項家電紀錄！</p>
					</div>
				</div>

			</div>
		</div>
		
	</div>
</section>







<!-- 單一圖片/影片 模板 -->
<ng-template #singleBanner let-input>

	<ng-container *ngIf="input.file_type=='image'">
		<a *ngIf="input.link_type=='in'" [routerLink]="[input.url]">
			<picture>
			  <!-- mobile -->
			  <source media="(max-width: 767px)" [srcset]="filepath+fileService.getThumbnail(input.m_filepath,'m')" />
			  <!-- desktop -->
			  <img [src]="filepath+fileService.getThumbnail(input.filepath,'bb')">
			</picture>
		</a>
		<a *ngIf="input.link_type=='out'" href="{{ input.url }}" target="_blank">
			<picture>
			  <!-- mobile -->
			  <source media="(max-width: 767px)" [srcset]="filepath+fileService.getThumbnail(input.m_filepath,'m')" />
			  <!-- desktop -->
			  <img [src]="filepath+fileService.getThumbnail(input.filepath,'bb')">
			</picture>
		</a>
		<ng-container *ngIf="input.link_type=='none'">
			<picture>
			  <!-- mobile -->
			  <source media="(max-width: 767px)" [srcset]="filepath+fileService.getThumbnail(input.m_filepath,'m')" />
			  <!-- desktop -->
			  <img [src]="filepath+fileService.getThumbnail(input.filepath,'bb')">
			</picture>
		</ng-container>


	</ng-container>

	<ng-container *ngIf="input.file_type=='video'">
		<a *ngIf="input.link_type=='in'" [routerLink]="[input.url]"><video controls [src]="filepath+input.filepath"></video></a>
		<a *ngIf="input.link_type=='out'" href="{{ input.url }}" target="_blank"><video controls [src]="filepath+input.filepath"></video></a>
		<video *ngIf="input.link_type=='none'" controls [src]="filepath+input.filepath"></video>
	</ng-container>
</ng-template>