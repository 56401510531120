import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  	constructor() { }

  	getThumbnail(path, size){
  		if(!path){
  			return path;
  		}
  		
		let arr = path.split('/');

	  	let filename = arr[arr.length-1];
	  
	  	let new_path = '';

	  	arr.forEach((item, key) => {
	  		if(key==arr.length-1){
	  			if(size=='xs'){
	  				new_path += `thumbs/${filename}`;
	  			}else{
	  				new_path += `thumbs_${size}/${filename}`;
	  			}
	  			
	  		}else{
	  			new_path += `${item}/`;
	  		}
	  	})
	  
	  	return new_path;
	}


	getFilename(path) {
		if(!path){
  			return path;
  		}
  		
		let arr = path.split('/');

	  	let filename = arr[arr.length-1];
	  	return filename;
	}
}
