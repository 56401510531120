import { Component, OnInit, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { CartService } from '../../services/cart.service';
import { FileService } from '../../services/file.service';
import { TokenService } from '../../services/token.service';
import { isPlatformBrowser } from '@angular/common';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss']
})
export class CartSummaryComponent implements OnInit {

	@Output() routeClick = new EventEmitter<any>(); //提供Output裝飾器，讓該事件成為父親模板的事件

	mobile;

	cart;
	sku_items;
	cart_empty;
	shipping_coupon_feedback;
	order_summary;
	order_dollbao_summary;
	filepath = environment.FileUrl;
	role;
	guest_id;
	shipping_fee;
	fixed_shipping_fee = 150;

	//相關連結位置
    product_info_link = '/products/info_sku'; //決定商品連結要用SKUmodel或product model去link
    discount_link = '/discount'; //單一活動頁面連結

  	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
  		private httpService: HttpService,
  		private cartService: CartService,
  		private tokenService: TokenService,
  		public fileService: FileService
  	) { }

  	ngOnInit(): void {
  		this.tokenService.infoState('member').subscribe(
			code => {

				//判斷是訪客還是會員
                switch (code) {

                    case "SUCCESS":
                        localStorage.setItem('user_role', 'member');
                    break;

                    //訪客
                    default:
                        localStorage.setItem('user_role', 'guest');
                    break;
                }

                this.role = localStorage.getItem('user_role');
                //抓取購物車資訊
                this.getCart();      
            }
        )
        


  		
  	}


  	//取得購物車資訊
  	getCart() {
  		
  		let url = `${environment.APIUrl}/api/lab-shop/front/carts/one?if_reload=${environment.cartDiscountReload.read}&`;

  		//訪客購物車
  		if(this.role=='guest'){
            let guest_id = this.cartService.getGuestId();
  			url = `${url}guest_id=${guest_id}&`;
            this.guest_id = guest_id;
  		}
  		

  		this.httpService.getHttp(url).subscribe(
  			res => {
                //購物車是空的 導回購物車列表or首頁
                if(res.success_code){
                	/*
                    swal.fire({
                        icon: 'error',
                        text: '此購物車無任何商品'
                    })*/
                    this.cart = null;
                    this.cart_empty = true;
                    this.cartService.updateCartCount(0);
                    //this.ngOnInit();
                    //this.router.navigate(['/']);
                   	return;
                }


  				//購物車id設定
  				this.cart = res;
               	this.sku_items = res.sku_datas;

                //取得購物車總價
                this.countTotal();
              
    			//免運券回饋
    			this.shipping_coupon_feedback = this.cart.free_shipping_info;
    		
              
  			}
  		);
  	}


  	//計算商品金額總和小計
    countTotal(){

    	//折扣總金額
    	/*
        let discount_total = this.cart.cart_discount_events.reduce(
            (runningValue: number, event)=> {
                  return runningValue = runningValue + event.discount;
            }, 0);
       */

       	this.order_summary = {
			product_total:this.cart.sum_total, //api算好的
			discount_event: -this.cart.discount_event_total, //行銷活動折扣api算好的
			member_discount_amount: -this.cart.member_discount_amount, //api算好的會員等級優惠
			coupon_discount: -this.cart.coupon_discount, //api算好的優惠券優惠
		};

		let final_pay = Object.keys(this.order_summary).reduce(
            (runningValue: number, key)=> {
                  return runningValue = runningValue + this.order_summary[key];
            }, 0);

		//最後需扣掉會員優惠折扣
        this.order_summary['final_pay'] = final_pay;


        //紅利點數商品商品 使用紅利點數商品數量
        let dollbao_product_total = this.cart.sku_datas.not_match.reduce(
            (runningValue: number, product)=> {
                  return runningValue = runningValue + (product.d_price*product.quantity);
            }, 0);

        this.order_dollbao_summary = {
        	dollbao_product_total: dollbao_product_total, 
        };

    }


    //計算包含運費的總額
    currentCheckTotal(){
    	//扣掉豆寶必
    	let final = this.order_summary.final_pay;
    	//console.warn(final)
    	//計算運費
    	if(this.cart.if_free_shipping){
    		this.shipping_fee = 0;
    	}else{
    		this.shipping_fee = this.fixed_shipping_fee;
    	}

    	return final+this.shipping_fee;
    }


    //關閉
	close(){
		this.routeClick.emit('goto');

		if(this.mobile){
			if(isPlatformBrowser(this.platformId)){
				document.querySelector('body').style.position = '';
			}
		}
	}

}
