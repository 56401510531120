import { BrowserModule, TransferState } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { 
  NbThemeModule, 
  NbLayoutModule, 
  NbSidebarModule, 
  NbMenuModule, 
  NbIconModule,
  NbDialogModule,
  NbTooltipModule,
  NbDatepickerModule,
  NbBadgeModule,
  NbActionsModule,
  NbSelectModule,
  NbCardModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';


//http client & interceptor
import { HttpClientModule,  HTTP_INTERCEPTORS } from '@angular/common/http';

//多語系
import {HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { LanguageService } from './services/language.service';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { translateBrowserLoaderFactory } from './services/translate-browser.loader';

//loading
import { NgxSpinnerModule } from "ngx-spinner";

//Interceptor
import { RequestInterceptor } from '../app/interceptors/request.interceptor';
import { ResponseInterceptor } from '../app/interceptors/response.interceptor';
import { TokenRefreshInterceptor } from '../app/interceptors/tokenRefresh.interceptor';
import { ErrorNotifierInterceptor } from '../app/interceptors/errorNotifier.interceptor';

//sweetalert2
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

//log
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

//gobal var
import { environment } from '../environments/environment';
import { FileLibModule } from '@fresh08man/file-lib';

//form
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//slider
import { CarouselModule } from 'ngx-owl-carousel-o';

//image lazy loading
import { LazyLoadImageModule, IntersectionObserverHooks, Attributes, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image'; 

//social login
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";


//component
import { AdminHomeComponent } from './layout/admin-home/admin-home.component';
import { AdminMenuComponent } from './layout/admin-menu/admin-menu.component';
import { HomeComponent } from './layout/home/home.component';
import { ProductMenuComponent } from './layout/product-menu/product-menu.component';
import { BannerComponent } from './layout/banner/banner.component';
import { IndexComponent } from './layout/index/index.component';
import { LangComponent } from './layout/lang/lang.component';
import { HomeProductItemComponent } from './layout/home-product-item/home-product-item.component';
import { CartSummaryComponent } from './layout/cart-summary/cart-summary.component';
import { IgDialogComponent } from './layout/ig-dialog/ig-dialog.component';


//when ssr skip lazyloading image
@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {
  	skipLazyLoading(attributes: Attributes) {
    	return isPlatformServer(this.platformId); 
  	}
}


 //Http interceptor providers in outside-in order 
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  //{ provide: HTTP_INTERCEPTORS, useClass: TokenRefreshInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorNotifierInterceptor, multi: true },
  { provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks } //LazyLoadImage
];

// 建立TranslateHttpLoader作為語系檔的讀取器
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime());
}
/*
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}*/

@NgModule({
  declarations: [
    AppComponent,
    AdminHomeComponent,
    AdminMenuComponent,
    HomeComponent,
    ProductMenuComponent,
    BannerComponent,
    IndexComponent,
    LangComponent,
    HomeProductItemComponent,
    CartSummaryComponent,
    IgDialogComponent,
  ],
  imports: [
    NbSelectModule,
    NbCardModule,
    CarouselModule,
    NbActionsModule,
    NbBadgeModule,
    FileLibModule.forRoot(environment),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbIconModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTooltipModule,
    HttpClientModule,
    NgxSpinnerModule,
    SweetAlert2Module.forRoot(),  
    LoggerModule.forRoot({
        serverLoggingUrl: '/api/logs', 
        //level: NgxLoggerLevel.DEBUG, 
        level: environment.debugger ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.ERROR,
        serverLogLevel: NgxLoggerLevel.ERROR
      }),
    
    //TransferHttpCacheModule,
    
    TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),

    LazyLoadImageModule,
    SocialLoginModule,
    //TransferHttpCacheModule //client端不重新loading 資料都以server端的為準(速度加快 但server端抓不到token)

  ],
  providers: [
  	httpInterceptorProviders,
  	{
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FBappId)
          }
        ]
      } as SocialAuthServiceConfig,
    }
 ],
  bootstrap: [AppComponent]
})

export class AppModule { 
	
    constructor(private languageService: LanguageService) {
        this.languageService.setInitState();
    }
}
