import { Injectable, Inject, PLATFORM_ID, Injector } from '@angular/core';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

declare let gtag: Function;
declare let ga: Function;

@Injectable({
  providedIn: 'root'
})
export class GaService {

	productList = {
		"product": "商品列表",
		"recommend": "推薦列表",
		"recommend_product": "商品推薦列表",
		"recommend_sku": "SKU推薦列表",
		"browser": "瀏覽紀錄",
		"favoriate": "我的最愛"
	};

  	constructor(
  		@Inject(PLATFORM_ID) private platformId: Object,
  		private translate: TranslateService
  	) { }


  	//商品列表使用 商品曝光
    gaAddImpression(products, listType, category='all') {

    	if(isPlatformBrowser(this.platformId) && environment.GA.addImpression){

    		let index = 1;
    		let listname = this.productList[listType];
    		
    		products.forEach(product => {
    		
	    		ga('ec:addImpression', {
	    			id: product.model,
	    			name: product.name,
	    			category: category,
	    			brand: '品牌',
	    			variant: '規格',
					list: listname,
					position: index
	    		});

	    		index++;
	    	});

			ga('send', 'event', listname, 'impression', category);

    	}

    }


    //點擊商品連結
    gaProductClick(product, listType, category='all') {

    	if(isPlatformBrowser(this.platformId) && environment.GA.productClick){

    		let listname = this.productList[listType];

	    	ga('ec:addProduct', { 
	    		id: product.model, 
	    		name: product.name, 
	    		category: category,
	    		brand: '品牌', 
	    		variant: '規格', 
	    		position: 1
			});

			ga('ec:setAction', 'click', {list: listname});
			ga('send', 'event', listname, '點擊商品連結');
		}
    }


    //查看商品詳情
    gaPrdouctDetail(product, brand_name=null) {

    	let category = null;
    	if(product.category){
    		category = product.category.url_code;
    	}

    	if(isPlatformBrowser(this.platformId) && environment.GA.productDetail){
	    	ga('ec:addProduct', { 
	    		id: product.model, 
	    		name: product.name, 
	    		category: category, 
	    		brand: brand_name, 
	    		variant: '規格'
			});
			ga('ec:setAction', 'detail');
			ga('send', 'event', '商品詳細頁', '瀏覽');
		}
    }


    //商品加入購物車
    gaAddCart(skus) {
    	if(isPlatformBrowser(this.platformId) && environment.GA.addCart){

    		skus.forEach(sku=> {
    			let option = '';
    			if(sku.options){
    				Object.keys(sku.options).forEach(key => {
		    			option += `${key}:${sku.options[key]}, `;
		    		})
    			}

	    		let brand_name = null;
	    		if(sku.brand){
	    			brand_name = sku.brand.name;
	    		}

	    		let category = null;
	    		if(sku.category){
	    			category = sku.category.url_code
	    		}

	    		ga('ec:addProduct', { 
	    			id: sku.model, 
	    			name: sku.product_name, 
	    			category: category, 
	    			brand: brand_name, 
	    			variant: option, 
	    			price: sku.member_price, 
	    			quantity: sku.buy_quantity
				});
				ga('ec:setAction', 'add');
				ga('send', 'event', '購物車', '加入購物車');
    		})
    		
    	}
	}


	//商品移除購物車
	gaRemoveCart(skus) {
		if(isPlatformBrowser(this.platformId) && environment.GA.removeCart){

			skus.forEach(sku=> {
    			let option = '';
    			if(sku.options){
    				Object.keys(sku.options).forEach(key => {
		    			option += `${key}:${sku.options[key]}, `;
		    		})
    			}

	    		let brand_name = null;
	    		if(sku.brand){
	    			brand_name = sku.brand.name;
	    		}

	    		let category = null;
	    		if(sku.category){
	    			category = sku.category.url_code
	    		}

	    		ga('ec:addProduct', { 
	    			id: sku.model, 
	    			name: sku.product_name, 
	    			category: category, 
	    			brand: brand_name, 
	    			variant: option, 
	    			price: sku.member_price, 
	    			quantity: sku.buy_quantity
				});
				ga('ec:setAction', 'remove');
				ga('send', 'event', '購物車', '移除購物車');
    		})

    		
    	}
	}


	//購物車流程
	gaCartSteps(sku_datas, step, option=null){
		if(isPlatformBrowser(this.platformId) && environment.GA.cartSteps){
		

			//活動商品
			if(sku_datas.match.length>0){
				sku_datas.match.forEach(match=>{
					match.skus.forEach(item=>{
						//非任選配對
						if(item.type!='ab'){
							ga('ec:addProduct', { 
				    			id: item.model, 
				    			name: item.product_name, 
				    			category: null, 
				    			brand: null, 
				    			variant: item.options, 
				    			price: item.after_discount_price, 
				    			quantity: item.quantity
							});
						}
						//子商品 （多料號、任選配對、組合包）
						if(item.children){
							item.children.forEach(child=>{
								ga('ec:addProduct', { 
					    			id: child.product_model, 
					    			name: child.product_name, 
					    			category: null, 
					    			brand: null, 
					    			variant: child.options, 
					    			price: child.after_discount_price, 
					    			quantity: child.quantity
								});
							})
						}
					})
				})
			}
			


			//非活動商品
			sku_datas.not_match.forEach(item=>{
				ga('ec:addProduct', { 
		    		id: item.model, 
		    		name: item.product_name, 
		    		category: null, 
		    		brand: null, 
		    		variant: item.options, 
		    		price: item.after_discount_price, 
		    		quantity: item.quantity
				});
			})


			
			// end for
			if(option){
				ga('ec:setAction','checkout', {'step': step, 'option':option});
			}else{
				ga('ec:setAction','checkout', {'step': step});
			}
			
		}
	}


	//完成購物 訂單建立成功
	gaCartFinish(order_number, order_total, shipping_fee, skus){
		if(isPlatformBrowser(this.platformId) && environment.GA.cartFinish){

			//訂單商品
			skus.forEach(sku=>{

				let brand_name = null;
	    		if(sku.brand){
	    			brand_name = sku.brand.name;
	    		}

	    		let category = null;
	    		if(sku.category){
	    			category = sku.category.url_code
	    		}

				ga('ec:addProduct', { 
	    			id: sku.model, 
	    			name: sku.name, 
	    			category: category, 
	    			brand: brand_name, 
	    			variant: sku.options, 
	    			price: sku.price, 
	    			quantity: sku.quantity
				});

				if(sku.children){
					sku.children.forEach(child=>{

						brand_name = null;
			    		if(child.brand){
			    			brand_name = child.brand.name;
			    		}

			    		category = null;
			    		if(child.category){
			    			category = child.category.url_code
			    		}
						ga('ec:addProduct', { 
			    			id: child.model, 
			    			name: child.name, 
			    			category: category, 
			    			brand: brand_name, 
			    			variant: child.options, 
			    			price: child.price, 
			    			quantity: child.quantity
						});
					})
				}
			})

			ga('ec:setAction', 'purchase', {
				id: order_number,
				affiliation: '逗寶',
				revenue: order_total,
				shipping: shipping_fee
			});
			ga('send', 'event', '購物車', '訂購成功');
		}
	}


	//gtm建立訂單
	gtmCartFinish(order_number, order_total, shipping_fee, skus){
		if(isPlatformBrowser(this.platformId) && environment.GTM.cartFinish){

			//console.warn('gtm cart finish start');
			//console.warn(skus);

			(<any>window).dataLayer.push({ ecommerce: null });

			let products = [];

			//訂單商品
			skus.forEach(sku=>{

				//console.warn(sku);

				let brand_name = null;
	    		if(sku.brand){
	    			brand_name = sku.brand.name;
	    		}

	    		let category = null;
	    		if(sku.category){
	    			category = sku.category.url_code
	    		}

	    		let product = { 
	    			id: sku.model, 
	    			name: sku.name, 
	    			category: category, 
	    			brand: brand_name, 
	    			variant: sku.options, 
	    			price: sku.price, 
	    			quantity: sku.quantity
				};

				products.push(product);

				//console.warn(products);

				if(sku.children){

					//console.warn(sku.children)
					sku.children.forEach(child=>{
						//console.warn(child)
						brand_name = null;
			    		if(child.brand){
			    			brand_name = child.brand.name;
			    		}

			    		category = null;
			    		if(child.category){
			    			category = child.category.url_code
			    		}
						products.push({ 
			    			id: child.model, 
			    			name: child.name, 
			    			category: category, 
			    			brand: brand_name, 
			    			variant: child.options, 
			    			price: child.price, 
			    			quantity: child.quantity
						});
					})
				}
			});// end foreach

			//console.warn('sku foreach end');

			(<any>window).dataLayer.push({
    			'event': 'checkout',
    			'ecommerce': {
      				'checkout': {
        				'actionField': {'step': 1, 'option': 'Visa'},
        				'products': products
     				}
     			}
   			});

   			//console.warn('gtm cart finish end');
   			//console.warn(products);
		}//end if
	}


}
