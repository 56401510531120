import { Component, OnInit, Inject, PLATFORM_ID, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { TreeService } from '../../services/tree.service';
import { FileService } from '../../services/file.service';
import { MetaService } from '../../services/meta.service';
import { isPlatformBrowser } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { IgDialogComponent } from '../ig-dialog/ig-dialog.component';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

	defaultImage = environment.lazyLoad_defaultImg;
	filepath = environment.FileUrl;

	//angular loading完才顯示區塊
	after_loading;

	//區塊開關控制
	setting; 


    data;
    ig;
    ig_1 = [0,1,2,3,4];
    ig_2 = [5,6,7,8,9];
 	mobile;


    //主題商品類別
    topics;
    topic_index = 0;

  	constructor(
  		@Inject(PLATFORM_ID) private platformId: Object,
  		private translate: TranslateService,
        private httpService: HttpService,
        private treeService: TreeService,
        private metaService: MetaService,
        public sanitizer: DomSanitizer,
        private dialogService: NbDialogService,
        public fileService: FileService
  	) { }

  	ngOnInit(): void {
        this.getData();

  		if(isPlatformBrowser(this.platformId)){
	        if (window.screen.width < 992) {
	          this.mobile = true
	        }
	        this.after_loading = true;
	        
	    }
  	}



    //取得首頁資訊
    getData(){
        let url = `${environment.APIUrl}/api/lab-web-content/front/home-data`;
        
        this.httpService.getHttp(url).subscribe(
            res => {
            	
                this.data = res;

                //SEO
                if(res.metadata){
                	this.metaService.setMetaByData(res.metadata)
                }
               	

                
	                
            }
        )
    }


    //IG popup
    openIGDialog(index) {
    	this.dialogService.open(IgDialogComponent, 
    		{ 
    			context: {
    				ig: this.ig,
    				index: index
    			}
    		}
    	);
      // document.querySelector('html').classList.add('overflow-hidden');
  	}


  	ngOnDestroy(){
  		this.metaService.setMetaByDefault()
  	}


   
}
