<nb-layout [restoreScrollTop]=false>
	<!-- 語系切換 -->
	<!-- <div class="col-lg-2 col-lang">
			<app-lang class="px-1"></app-lang>
	</div> -->


	<!-- Header -->
	<nb-layout-header fixed>
		<header class="header">

			<nav class="navbar navbar-expand-lg">
			  <div class="container-fluid px-md-4">
			    
			    <a class="navbar-brand mx-lg-4 pe-lg-3" [routerLink]="['/']" (click)="closeMenu()">
      			<picture>
              <!-- mobile -->
              <source media="(max-width: 991px)" srcset="/assets/images/logo.svg">
              <!-- desktop -->
              <img src="/assets/images/logo.png" alt="好巢網">
            </picture>
	        </a>

			    <div id="navbar" class="collapse navbar-collapse order-2 order-lg-1">
			      <ul class="navbar-nav me-auto mb-2 mb-lg-0">

			        <li class="nav-item">
			          <a class="nav-link" [routerLink]="['/content/page/brand-history']" (click)="closeMenu()">關於好巢</a>
			        </li>

			        <li class="nav-item nav-main-category">
			          <a class="nav-link dropdown-toggle" [ngClass]="{'show': menu_open}" (click)="toggleMainMenu()">找好物</a>
			          <!-- 找好物menu -->
			          <div *ngIf="menu_open" class="sub-menu">
			          	<app-product-menu (routeClick)="toggleMainMenu()"></app-product-menu>
			          </div>
			        </li>

			        <li class="nav-item">
			          <a class="nav-link" [routerLink]="['/content/blog/0']" (click)="closeMenu()">家務指南</a>
			        </li>

			        <li class="nav-item">
			          <a class="nav-link" [routerLink]="['/member/product-resume']" (click)="closeMenu()">家電履歷</a>
			        </li>

			        <!-- 手機版才有的會員中心 -->
			        <li class="nav-item dropdown nav-main-category d-lg-none">
                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" (click)="menu_open=false">
                  會員中心
                </a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" [routerLink]="['/member/info']" (click)="closeMenu()">-會員資料</a></li>
                  <li><a class="dropdown-item" [routerLink]="['/member/order/records']" (click)="closeMenu()">-訂單查詢</a></li>
                  <li><a class="dropdown-item" [routerLink]="['/member/favorite']" [queryParams]="{model_type:'products'}" (click)="closeMenu()">-我的收藏</a></li>
                  <li><a class="dropdown-item" [routerLink]="['/member/account']" (click)="closeMenu()">-優惠福利</a></li>
                  <li><a class="dropdown-item" [routerLink]="['/member/product-resume']" (click)="closeMenu()">-家電履歷</a></li>
                </ul>
              </li>

			      </ul>
			    </div>

		      <ul class="navbar-nav navbar-func order-1 order-lg-2">

		      	<!-- 搜尋 -->
		      	<li class="nav-item">
		      		<a type="button" class="nav-link" (click)="toggleSearchMenu()">
			      		<nb-icon icon="search"></nb-icon>
		      		</a>
		      	</li>

		      	<!-- 購物車 -->
		      	<li class="nav-item">
		      		<a type="button" class="nav-link" [routerLink]="['/cart/order']" (click)="closeMenu()">
		      		<!--button type="button" class="nav-link" (click)="cartService.openCartDialog()"-->
		      			<nb-icon icon="shopping-cart-outline"></nb-icon>
		      			<span *ngIf="cartService.cart_count!='0'" class="cart-count">{{ cartService.cart_count }}</span>
		      		</a>
		      	</li>

		      	<!-- 登入／註冊 -->
		      	<li *ngIf="!user" class="nav-item d-none d-lg-block">
		      		<a [routerLink]="['/auth/login']" class="nav-link" (click)="closeMenu()">
		      			<nb-icon icon="person-outline"></nb-icon>
		      		</a>
		      	</li>
		      	
		      	<!-- 已登入/登出 -->
		      	<li *ngIf="user" class="nav-item d-none d-lg-block">
		      		<!--p>{{ user.name }}</p><br-->
		      		<a [routerLink]="['/member/info']" class="nav-link" (click)="closeMenu()">
		      			<nb-icon icon="person"></nb-icon>
		      		</a>
		      		<!-- <a (click)="authService.logout()" class="nav-link d-none d-lg-block" (click)="closeMenu()">登出</a> -->
		      	</li>

		      	<!-- 手機版選單展開按鈕 -->
		      	<li class="nav-item d-lg-none">
		      		<button id="toggle" class="nav-link ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-expanded="false">
		      		  <nb-icon icon="menu"></nb-icon>
		      		  <nb-icon icon="close"></nb-icon>
		      		</button>
		      	</li>

		      </ul>

			  </div>
			</nav>

			<!-- 找好物menu -->
			<!-- <div *ngIf="menu_open" class="sub-menu">
				<div class="container-fluid">
					<app-product-menu (routeClick)="toggleMainMenu()"></app-product-menu>
				</div>
			</div> -->

			<!-- 搜尋menu -->
			<div *ngIf="search_open" class="search-menu">
				<div class="container-fluid">
					<div class="row gx-3 my-1">
						<div class="col col-md-4 col-lg-3 col-xl-2 ms-auto">
							<input type="search" [(ngModel)]="keyword" (keyup.enter)="webSearch()" class="form-control" placeholder="輸入關鍵字">
						</div>
						<div class="col-auto">
							<button class="btn btn-primary" type="button" (click)="webSearch()">搜尋</button>
						</div>
					</div>
				</div>
			</div>

			<!-- menu背景 -->
			<div *ngIf="menu_open || search_open || cart_open" class="backdrop" (click)="closeMenu()"></div>

		</header>
	</nb-layout-header>


	<!-- Footer -->
	<nb-layout-footer>
		<footer class="footer">
			<div class="container">
				
				<div class="row gy-4 gy-md-0 mt-md-2">
					<div class="col-6 col-md-2">
						<h5>關於好巢</h5>
						<div class="site-links">
							<a href="">品牌故事</a>
							<a href="">使用者條款</a>
							<a href="">隱私權保護</a>
							<a href="">成為夥伴</a>
						</div>
					</div>
					<div class="col-6 col-md-2">
						<h5>常見問題</h5>
						<div class="site-links">
							<a href="">防詐騙宣導</a>
							<a href="">配送方式</a>
							<a href="">退換貨須知</a>
							<a href="">常見Ｑ＆Ａ</a>
						</div>
					</div>
					<div class="col-12 col-md-5">
						<h5>公司資訊</h5>
						<p class="mb-2">Email：<a href="mailto:services@ho-nest.com.tw">services@ho-nest.com.tw</a></p>
						<p class="mb-2">聯絡電話：+886-2-7755-7798</p>
						<p class="mb-2">地址：105台北市松山區松江路160巷5-3號1樓</p>
					</div>
					<div class="col-12 col-md-3 text-md-end">
						<div class="footer-logo mb-4 d-none d-md-block">
							<picture>
				        <!-- mobile -->
				        <source media="(max-width: 991px)" srcset="/assets/images/logo_white.svg">
				        <!-- desktop -->
				        <img src="/assets/images/logo_white.png">
				      </picture>
						</div>

						<div class="mb-2">FOLLOW 好巢網</div>
						<div class="social-links">
							<a href="" class="me-3"><img src="/assets/images/social/facebook.svg" alt=""></a>
							<a href=""><img src="/assets/images/social/line.svg" alt=""></a>
						</div>

					</div>
				</div>
				
				<div class="copyright mt-5 lh-1">©2023 好巢網 版權所有 COPYRIGHT 2023</div>

			</div>
		</footer>
	</nb-layout-footer>


	<!-- 內頁 -->
 	<nb-layout-column>
 		<div class="fixed-header-space"></div>
 		
 		<router-outlet></router-outlet>

		<!--ngx-spinner [fullScreen]=true>
  		<p style="font-size: 16px; color: white">Loading...</p>
		</ngx-spinner-->
 	</nb-layout-column>
 	
</nb-layout>
