
<!-- 行銷活動(desktop) 
<ng-container *ngIf="!mobile">
	<ul *ngIf="level==1" class="nav nav-sale level-1">
		<li class="nav-item">
			<a class="nav-link">
				<span class="nav-text">Sale</span>

				第二層 
				<ul class="nav nav-sale level-2" [ngClass]="{'has-banner':discount_event_menu_banner}">

					全館優惠 
					<li class="nav-item">
						<a class="nav-link">
							<span class="nav-text">{{ 'home.menu.discount_shop' | translate }}</span>
						</a>
						第三層 
						<ul class="nav nav-sale level-3">
							<li class="nav-item" *ngFor="let discount_event of discount_events.shop">
								<a class="nav-link" [routerLink]="['/discount', discount_event.id]">
									<span class="nav-text">{{ discount_event.name }}</span>
								</a>					
							</li>
						</ul>
					</li>
					限定商品優惠
					<li class="nav-item">
						<a class="nav-link">
							<span class="nav-text">{{ 'home.menu.discount_product' | translate }}</span>
						</a>
						第三層 
						<ul class="nav nav-sale level-3">
							<li class="nav-item" *ngFor="let discount_event of discount_events.product">
								<a class="nav-link" [routerLink]="['/discount', discount_event.id]">
									<span class="nav-text">{{ discount_event.name }}</span>
								</a>					
							</li>
						</ul>
					</li>

					目錄廣告 
					<li *ngIf="discount_event_menu_banner" class="nav-banner"><img [src]="filepath+fileService.getThumbnail(discount_event_menu_banner.filepath,'s')" alt=""></li>
				</ul>
			</a>

		</li>
	</ul>
</ng-container>
-->

<!-- 搜尋 
<div class="search">
	<nb-icon icon="search"></nb-icon>
	<input type="search" [(ngModel)]="keyword" (keyup.enter)="webSearch()" class="form-control">
</div>-->

<!-- 分類目錄 -->
<ng-container>
	<ul class="dropdown-menu show dropdown-main-menu mx-2">
		<li>
			<a [routerLink]="['/products']" class="dropdown-item py-lg-2" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" (click)="outputEvent()">全部</a>
		</li>
		<li class="dropdown" *ngFor="let item of list; let i=index">

			<!-- 第一層 -->
			<a class="dropdown-item dropdown-toggle py-lg-2" data-bs-toggle="dropdown">{{ item.data.name }}</a>
			<!-- 第二層 -->
			<ul class="dropdown-menu">
				<li>
					<h5 class="dropdown-item dropdown-title">{{ item.data.name }}</h5>
				</li>
				<li>
					<a [routerLink]="['/products', item.data.url_code]" routerLinkActive="active" class="dropdown-item" fragment="category" (click)="outputEvent()">-全部</a>
				</li>
				<li *ngFor="let child of item.children">
					<a [routerLink]="['/products', child.data.url_code]" routerLinkActive="active" class="dropdown-item" fragment="category" (click)="outputEvent()">-{{ child.data.name | translate }}</a>
				</li>
			</ul>
		</li>
		
	</ul>

	<!-- 登出(mobile) 
	<div class="ps-5 mt-5">
		<a class="text-dark d-block d-lg-none" (click)="authService.logout()">登出</a>
	</div>-->
</ng-container>






