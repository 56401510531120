import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Location } from '@angular/common'; //接收route para
import { catchError, map, tap } from 'rxjs/operators';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
//import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material'; //popup dialog
//import { LoginComponent } from '../auth/login/login.component';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ClidLineGuard implements CanActivate {

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object, 
		private authService:AuthService, 
		private router:Router, 
		private route: ActivatedRoute
	) {}

	canActivate(
    	next: ActivatedRouteSnapshot,
    	state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean 
    {
    	return this.check(next, state);
    }


    canActivateChild(
    	next: ActivatedRouteSnapshot,
    	state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean 
    {
    	return this.check(next, state);
    }


    //進行權限檢查
    check(next, state){

    	console.warn("LINE GUARD")
    	console.warn(next.queryParams)

    	//從建強過來
    	if(next.queryParams.clid){
			//return true;

			
			return this.authService.clidLineLoginToken(next.queryParams.clid).pipe(

				map(code => {
					return true;
		      	})

	      	);

		//一般
    	}else{
    		return true;
    	}
    	
	
  	}
  
}
