<div class="card-product">
	<a [routerLink]="['/products/info', item.model]" class="card-image">
		<img [src]="filepath+fileService.getThumbnail(item.image,'xs')" class="card-img-top" alt="{{ item.name }}">
		<!-- 圖片外匡 -->
		<ng-container *ngIf="item.frame_image">
			<img [src]="filepath+fileService.getThumbnail(item.frame_image,'xs')" class="card-img-top">
		</ng-container>
	</a>
	<div class="card-body">
		<h6 class="card-subtitle">
			<a *ngIf="item.brand_name" [routerLink]="['/search/product-brand', item.brand_id]">
				{{ item.brand_name }}
			</a>
		</h6>
    
		<h5 class="card-title">
			<a [routerLink]="['/products/info', item.model]">
  			{{ item.name }}
    	</a>
  	</h5>
  	
		<p class="card-text">

			<!-- 價格 -->
			<ng-container *ngIf="selectSKU">
				<span class="price">NT${{ selectSKU.member_price }}元</span>
				<!-- 優惠價與原價相同時 不顯示原價 -->
				<span class="original" *ngIf="selectSKU.member_price!=item.original_price+selectSKU.original_price"><s>NT${{ item.original_price+selectSKU.original_price }}元</s></span>
			</ng-container>
			<ng-container *ngIf="!selectSKU">
				<span class="price">NT${{ item.price }}元</span>
				<span class="original"><s>NT${{ item.o_price }}元</s></span>
			</ng-container>

		    <!-- 新品 購物車按鈕 -->
		    <ng-container *ngIf="type=='new' || type=='classic' && mobile">
		    	<!-- 無規格 直接加入購物車 -->
			    <ng-container *ngIf="item.option_type==0; else multi">
			    	<!-- 有庫存 可加入購物車 -->
			    	<ng-container *ngIf="selectSKU.subtract==0 || (selectSKU.subtract==1 && selectSKU.quantity>0); else soldout">
				          <!-- 選擇規格
				          <div class="row">
				            <div class="form-group col" *ngFor="let option_category of item.option_categories">
				              <label class="label">{{ option_category.category_name }}</label>
				              <nb-select fullWidth [(ngModel)]="selectOptions[option_category.category_id]" (ngModelChange)="getSKU()">
				                <nb-option *ngFor="let option of option_category.options"  [value]="option.id.toString()">{{ option.name }}</nb-option>
				              </nb-select>
				            </div>
				          </div>-->
				    	<!-- 購買數量 
							<select class="custom-select" [(ngModel)]="selectNum">
								<option value="{{ i+1 }}" *ngFor="let num of numBuyArray(selectSKU.quantity, selectSKU.subtract) ;let i= index">{{ i+1 }}</option>
							</select>-->

						<!-- 加入購物車按鈕 -->
						<ng-container *ngIf="allowAddCart">
							<button *ngIf="!item.presale_status" type="button" class="btn btn-icon btn-cart" (click)="cartService.addCart(selectNum, selectSKU.id)"><nb-icon icon="shopping-cart-outline"></nb-icon></button>
							<button *ngIf="item.presale_status" type="button" class="btn btn-icon btn-cart" [swal]="[item.presale_text]" (confirm)="cartService.addCart(selectNum, selectSKU.id)"><nb-icon icon="shopping-cart-outline"></nb-icon></button>
						</ng-container>
						
							
						
					</ng-container><!-- 有庫存 可加入購物車 -->
				</ng-container><!-- 無規格 直接加入購物車 -->
			</ng-container><!-- 新品 購物車按鈕 -->

			<!-- 多規格導入商品詳細頁面 -->
			<ng-template #multi>
	      		<ng-container *ngIf="item.quantity_status; else soldout">
				    <button *ngIf="allowAddCart"
			              type="button" class="btn btn-icon btn-cart" 
			              [swal]="{ text: '此商品須選擇規格(尺寸/顏色等)，按下確定後會進入商品介紹頁面。請選擇好想要的規則再加入購物車' }"
			              (confirm)="router.navigate(['/products/info', item.model])"
			      	><nb-icon icon="shopping-cart-outline"></nb-icon></button>
	      		</ng-container>
			</ng-template>
		</p>

		<!-- 經典商品 shop now -->
		<ng-container *ngIf="!mobile && type=='classic'">
			<a [routerLink]="['/products/info', item.model]" class="btn btn-shop-now">Shop Now</a>
			<!--a *ngIf="link_type=='in'" [routerLink]="[url]" class="btn btn-shop-now">Shop Now</a>
			<a *ngIf="link_type=='out'" href="{{ url }}" target="_blank" class="btn btn-shop-now">Shop Now</a-->
		</ng-container>

    <!-- 加入收藏 
		<app-favorite [id]="item.id" type="products" [isFavorite]="item.is_favorite" (favoriteChange)="removeItem($event)"></app-favorite>-->
	</div>
</div>


<!-- 商品已售完 貨到通知 -->
<ng-template #soldout>
	<button *ngIf="allowAddCart" type="button" class="btn btn-icon btn-sold-out" (click)="cartService.deliveryNotice(item.id, selectSKU.id)">
		<nb-icon icon="bell-outline"></nb-icon>
	</button>
</ng-template>

