// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  	production: true,
  	debugger: true,
  	default_shop_id: '1',
  	default_shop_name: '家聯網官網測試區',
  	APIUrl: "https://honest-api.labspace.com.tw",
	APIUrlReg: "^https?:\/\/.*honest.labspace.com.tw",
	FileUrl: "https://honest-api.labspace.com.tw/storage",
	paymentUrl: "https://honest-api.labspace.com.tw/api/lab-shop/front/go-pay",
	subscriptionPaymentUrl: "https://honest-api.labspace.com.tw/api/lab-shop/front/bind-go-pay",
	FBappId: '640155467351810',
	GoogleAPIKey: '611748108892-8k1t9ihtanpvulpn37cnhp1p2qat5bm0.apps.googleusercontent.com', //for google login GoogleClientID
	//WebSocketUrl: "http://localhost/websocket/public",
	//WebSocketHost: "210.65.139.164:6001",
	webTitle: '家聯網官網測試區',
	GA: {
		trackingID: 'UA-123182296-24',
		page_view: false,
		addImpression: false,
		productClick: false,
		productDetail: false,
		addCart: false,
		removeCart: false,
		cartSteps: false,
		cartFinish: false
	},
	GTM: {
		cartFinish: true
	},
	//加入,修改,刪除購物車時 讀取購物車是否重新計算折扣
	cartDiscountReload: {
		write: 1,
		read: 0
	},
	lazyLoad_defaultImg: '/assets/images/lazyload.png',
	recaptchSiteKey: '6Lfq5CAUAAAAAFK68KUshV0WjGxaE56L_XL9DQHo',
	smsTimeout: 60,
	favIcon: 'favicon.ico',
	LINELoginUrl: 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657432502&redirect_uri=https://uat-api.ho-nest.com.tw/api/lab-auth/line-callback&state=get-code&scope=openid%20email%20profile',
	liffID: '1656799451-5Xvboznk'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
