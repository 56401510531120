import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class LayoutService {

	mobile;
	tablet;

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
	) { }

	screenSize() {
		if(isPlatformBrowser(this.platformId)){
			if(window.screen.width < 992){
				this.mobile = true;
			}
			if(window.screen.width < 768){
				this.tablet = true;
			}
		}
	}

	
	// 連結中有#時 滑動至id錨點位置
	// clickToAnchorPosition() {
	// 	this.delegate(document, "click", "a", function(event) {
	// 		if(this.hasAttribute('href') && this.getAttribute('href').indexOf('#') > 0){
	// 			event.preventDefault();
	// 			let id = this.getAttribute('href').split("#")[1];
	// 			setTimeout(() => {
	// 				window.scrollTo({
	// 					top: document.getElementById(id).offsetTop - 80,
	// 					left: 0,
	// 					// behavior: 'smooth'
	// 				});
	// 			}, 0)
	// 			// 切換到類別全部時 需要延遲400ms才能定位到該id錨點位置
	// 			setTimeout(() => {
	// 				window.scrollTo({
	// 					top: document.getElementById(id).offsetTop - 80,
	// 					left: 0,
	// 					// behavior: 'smooth'
	// 				});
	// 			}, 400)
	// 		}
	// 	});
	// }
	

	// 幫js產生的元素綁定事件
	delegate(el, evt, sel, handler) {
		el.addEventListener(evt, function(event) {
			var t = event.target;
			while (t && t !== this) {
				if (t.matches(sel)) {
					handler.call(t, event);
				}
				t = t.parentNode;
			}
		});
	}


  gotoTop(){
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

}
