import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { CartService } from '../../services/cart.service';
import { ViewEncapsulation } from '@angular/core';
import { MetaService } from '../../services/meta.service';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { TreeService } from '../../services/tree.service';
import { isPlatformBrowser } from '@angular/common';
import { FileService } from '../../services/file.service';
import { LayoutService } from '../../services/layout.service';
import { CookieService } from '../../services/cookie.service';
import {  Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router'; //接收route para
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

	defaultImage = environment.lazyLoad_defaultImg;

	setting; //區塊顯示控制

    data;
    user;
    footers = ['f-about','f-help','f-review','f-knowledge','f-hot','f-brand'];
    footers_toggle;
    filepath = environment.FileUrl;
    
    mobile;
    tablet;
    menu_open = false;
    search_open = false;
    cart_open = false;
    //cart_quantity;

    //站內搜尋表單
    keyword;
  		
  	constructor(
  		@Inject(PLATFORM_ID) private platformId: Object,
  		private formBuilder: FormBuilder,
        public authService: AuthService,
        public cartService: CartService,
        private metaService: MetaService,
        private httpService: HttpService,
        private treeService: TreeService,
        private cookieService: CookieService,
        public fileService: FileService,
        public layoutService: LayoutService,
        private router: Router,
        private tranalate: TranslateService
    ) { }

  	ngOnInit(): void {
  		
        this.getData();
        this.metaService.setMetaByDefault();

        //手機版判斷
        if(isPlatformBrowser(this.platformId)){
        	if(window.screen.width < 992){
	        	this.mobile = true;
	        }

	        if(window.screen.width < 768){
	        	this.tablet = true;
        	}
        }

  	}


  	ngAfterViewInit(){
  		//this.sslSeal();
  	}


    //取得header footer資料
    getData() {
        let url = `${environment.APIUrl}/api/lab-web-content/front/common-data?check_user=1`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.data = res;
                //this.setting = res.settings;
                this.cartService.updateCartCount(res.cart_quantity);
                //this.cart_quantity = res.cart_quantity;
                this.user = res.user_info;
            }
        )
    }


  	toggleMainMenu(){
  		this.menu_open = !this.menu_open;
  		if(this.menu_open){
  			this.search_open = false;
        this.cart_open = false;
  		}
  	}


    toggleSearchMenu(){
      this.search_open = !this.search_open;
      if(this.search_open){
        this.menu_open = false;
        this.cart_open = false;
      }
    }


  	toggleCart(){
  		this.cart_open = !this.cart_open;
  		if(this.cart_open){
  			this.menu_open = false;
  		}
  	}


    closeMenu(){
        this.menu_open = false;
        this.search_open = false;
        this.cart_open = false;
        if(isPlatformBrowser(this.platformId)){
          document.getElementById('navbar').classList.remove('show');
          document.getElementById('toggle').setAttribute('aria-expanded', 'false');
        }
    }


    //動態ssl標章
    sslSeal() {
    	if(isPlatformBrowser(this.platformId)){
	    	const jsElm1 = document.createElement('script');
		    jsElm1.type = 'text/javascript';
		    jsElm1.charset = 'utf-8';
		    jsElm1.text = 'var twca_cn="www.dollbao.com.tw";';

		    const jsElm2 = document.createElement('script');
		    jsElm2.type = 'text/javascript';
		    jsElm2.charset = 'utf-8';
		    jsElm2.src = '//ssllogo.twca.com.tw/twcaseal_v3.js';

		    document.body.appendChild(jsElm1);
		    document.body.appendChild(jsElm2);
		}
    }


    //站內關鍵字搜尋
    webSearch(){
    	if(this.keyword){
    		this.router.navigate(['/search', this.keyword, 'product']);
        this.closeMenu();
    	}

    	
    }

}
